import { useLocation } from '@reach/router';
import clsx from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';
import { Link, Trans, useI18next } from 'gatsby-plugin-react-i18next';
import { usePostHog } from 'posthog-js/react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { registerGoogleEvent } from '../../shared/utils';
import * as classes from './Cover.module.scss';

const Cover = props => {
    const { title, subtitle, cover, localizeCover = false } = props;
    const location = useLocation();
    const { language } = useI18next();
    const [ actions, setActions ] = useState(<>
        <a
            href={`/app/auth/register?from=${location.pathname}`}
            className="btn btn-primary btn-wide"
            onClick={() => handleCallToActionClick('primary')}
            id="cover-primary"
        >
            <Trans>Get started</Trans>
        </a>
        <Link to={'/support/contacts'} className="btn btn-secondary btn-wide dark" id="cover-secondary"
              onClick={() => handleCallToActionClick('secondary')}>
            {<Trans>Contact us</Trans>}
        </Link>
    </>);

    const posthog = usePostHog();

    useEffect(() => {
        // Check PostHog feature flag
        if (posthog.getFeatureFlag('contact-over-register') === 'test') {
            // Set primary action based on feature flag
            setActions(<>
                <Link to="/support/contacts" className="btn btn-primary btn-wide" id="call-to-action-secondary">
                    <Trans>Contact us</Trans>
                </Link>
            </>);
        }
    }, [ posthog, location.pathname ]);

    const handleCallToActionClick = source => {
        const label = `${source}_${location.pathname}`;
        registerGoogleEvent('click_callToAction', label);
        if (source === 'primary') {
            registerGoogleEvent('sign_up_intent');
        }

        return true;
    };

    const data = useStaticQuery(graphql`
      query {
        allFile(filter: { relativePath: { regex: "/aida-cover-/" } }) {
          nodes {
            childImageSharp {
              gatsbyImageData(height: 800, placeholder: BLURRED, quality: 90)
            }
            relativePath
          }
        }
      }
    `);

    const suffix = localizeCover ? `-${language}` : '';
    const fileName = `aida-cover-${cover}${suffix}`;
    const imageNode = data.allFile.nodes.find(node => node.relativePath.indexOf(`covers/${fileName}`) === 0);
    const backgroundImage = `url('${getSrc(imageNode)}')`;

    return <div className={classes.Cover} style={{ backgroundImage }}>
        <div className={clsx(classes.Overlay, 'd-xl-none')} />
        <div className={clsx(classes.BackgroundImage, 'd-xl-none')} style={{ backgroundImage }} />
        <div className="container p-0" style={{ display: 'flex' }}>
            <div className={classes.Content}>
                <h1>{title}</h1>
                {subtitle && <h2>{subtitle}</h2>}
                <div className={classes.Buttons}>
                    {actions}
                </div>
            </div>
        </div>
    </div>;
};

Cover.propTypes = {
    title: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]).isRequired,
    subtitle: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]).isRequired,
    cover: PropTypes.string.isRequired,
    localizeCover: PropTypes.bool
};

export default Cover;
