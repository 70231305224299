import clsx from 'clsx';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { find } from 'lodash';
import * as React from 'react';
import Cover from '../../components/Cover/Cover';
import { Seo } from '../../components/Seo';
import Layout from '../../layouts/layout';
import * as classes from './features.module.scss';

const AidaGptPage = props => {
    const { t } = useTranslation();

    return <Layout location={props.location}>
        <Cover
            title={<Trans i18nKey="aida-gpt-page:pageTitle">AIDA-GPT</Trans>}
            subtitle={<Trans i18nKey="aida-gpt-page:pageSubtitle">Experience a new way to interact with documents with
                                                                  AIDA-GPT chat</Trans>}
            cover="aida-gpt"
            localizeCover={true}
        />
        <div className="container p-0 intro">
            <Trans i18nKey="aida-gpt-page:pageDescription">
                <p>Take your document management to the next level with <strong>AIDA-GPT</strong>, the revolutionary
                   chat-based tool that transforms how you interact with your documents.
                   With AIDA-GPT, you can navigate through documents, extract information, and generate content with
                   ease, all in a fully immersive chat environment that saves you valuable time and resources.</p>
                <p>AIDA-GPT's innovative features include <strong>Document Chat</strong>, which lets you conduct
                   conversations on a single or multiple documents,
                    <strong>Quick Commands</strong> for executing complex tasks with a single click, <strong>content
                                                                                                             generation</strong> for
                   automating repetitive tasks, and <strong>Graphs</strong>
                   that provide a comprehensive visualization of your data. Experience a new way to approach document
                   management with <strong>AIDA-GPT</strong> and unlock new insights from your data today.</p>
            </Trans>
        </div>
        <div className={clsx(classes.FeaturesContainer)}>
            <div className={classes.Features}>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-0', classes.Picture)}>
                        <StaticImage src="../../images/aida-gpt/aida-gpt-01-document-chat.jpg" alt="AIDA" />
                    </div>
                    <div className={clsx('col-md-6 order-1', classes.Description)}>
                        <div>
                            <div className={classes.SmallTitle}>{t('aida-gpt-page:pageTitle')}</div>
                            <h2><Trans>Revolutionize information retrieval with document chat</Trans></h2>
                            <Trans i18nKey="aida-gpt-page:feature1">
                                <p>Immerse yourself in the <strong>future of document navigation</strong> with
                                   AIDA-GPT's Document Chat.
                                   This feature allows you to conduct <strong>conversations on single or multiple
                                                                              documents</strong>,
                                   presenting a groundbreaking way to extract contextually precise information
                                   that <strong>transcends traditional boundaries</strong> of data extraction.</p>
                                <p>Instead of combing through entire documents, simply <strong>ask direct
                                                                                               questions</strong> and
                                   receive instant answers, all in the full context of your document's data.</p>
                            </Trans>
                        </div>
                    </div>
                </div>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-1 order-md-0', classes.Description)}>
                        <div>
                            <div className={classes.SmallTitle}>{t('aida-gpt-page:pageTitle')}</div>
                            <h2><Trans>Enhance productivity with quick commands</Trans></h2>
                            <Trans i18nKey="aida-gpt-page:feature2">
                                <p>Unlock the full potential of your document management system with AIDA-GPT's Quick
                                   Commands.
                                   These features enable you to perform <strong>complex tasks on multiple documents at
                                                                                once</strong> such as summarizing,
                                   translating, creating complex financial reports, finding differences and more, with a
                                   single click.</p>
                                <p>Quick Commands are more than just a tool: they are your <strong>personal
                                                                                                   assistant</strong> in
                                   navigating through the extensive world of document data.</p>
                            </Trans>
                        </div>
                    </div>
                    <div className={clsx('col-md-6 order-0 order-md-1', classes.Picture)}>
                        <StaticImage src="../../images/aida-gpt/aida-gpt-02-quick-commands.jpg" alt="AIDA" />
                    </div>
                </div>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-0', classes.Picture)}>
                        <StaticImage src="../../images/aida-gpt/aida-gpt-03-generational-ai.jpg" alt="AIDA" />
                    </div>
                    <div className={clsx('col-md-6 order-1', classes.Description)}>
                        <div>
                            <div className={classes.SmallTitle}>{t('aida-gpt-page:pageTitle')}</div>
                            <h2><Trans>Automate content creation</Trans></h2>
                            <Trans i18nKey="aida-gpt-page:feature3">
                                <p>Unleash the power of automation with AIDA-GPT's content generation feature.
                                   This game-changing tool <strong>automates repetitive tasks</strong>, helping you
                                   focus on high-value activities by auto-generating various content from your
                                   documents.</p>
                                <p>From drafting contract terminations to creating personalized customer communications,
                                   AIDA-GPT handles it all with ease. Need to produce multiple invoices or project
                                   updates? Let AIDA-GPT do the work,
                                   saving you <strong>valuable time</strong> and boosting your productivity.</p>
                            </Trans>
                        </div>
                    </div>
                </div>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-1 order-md-0', classes.Description)}>
                        <div>
                            <div className={classes.SmallTitle}>{t('aida-gpt-page:pageTitle')}</div>
                            <h2><Trans>Visualize your data with AIDA-GPT's graphs</Trans></h2>
                            <Trans i18nKey="aida-gpt-page:feature4">
                                <p>AIDA-GPT's Graph feature brings a new dimension to your data by creating <strong>visual
                                                                                                                    representations</strong> of
                                   your information.
                                   It provides an intuitive and user-friendly way to understand your data, making it an
                                   essential tool for any business.</p>
                                <p>The Graph feature is a powerful tool that goes beyond visualization, all within
                                   a <strong>simple chat request</strong>.
                                   By combining graphs with comprehensive document data, users can now unlock hidden
                                   insights and uncover valuable trends that <strong>may not be immediately
                                                                                     apparent</strong> on the documents
                                   themselves.</p>
                            </Trans>
                        </div>
                    </div>
                    <div className={clsx('col-md-6 order-0 order-md-1', classes.Picture)}>
                        <StaticImage src="../../images/aida-gpt/aida-gpt-04-data-charts.jpg" alt="AIDA" />
                    </div>
                </div>
            </div>
        </div>
    </Layout>;
};

export default AidaGptPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
        filter: {ns: {in: ["common", "aida-gpt-page"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
    const edge = find(data.locales.edges, [ 'node.ns', 'aida-gpt-page' ]);
    let obj = undefined;
    if (!!edge) {
        obj = JSON.parse(edge.node.data);
    }
    const title = !!obj ? obj.title : 'AIDA-GPT';

    return <Seo title={title} description={!!obj ? obj.description : null} />;
};
